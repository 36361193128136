@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&family=Nunito+Sans:wght@200&family=Rubik:ital@0;1&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background-color: black;
  scroll-behavior:smooth;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  /* list-style: none; */
  /* list-style-position: inside; */
  margin-left: 1em;
  text-align: left;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "coder";
  src: local("coder"), url(/static/media/font.617a0a92.woff) format("woff");
  font-display: swap;
}

@font-face {
    font-family: "hairline";
    src: url(/static/media/aktivgrotesk-hairline-webfont.c78e8497.woff2) format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "light";
    src: url(/static/media/aktivgrotesk-light-webfont.a9b47fde.woff2) format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "thin";
    src: url(/static/media/aktivgrotesk-thin-webfont.a9917764.woff2) format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "regular";
    src: url(/static/media/aktivgrotesk-regular-webfont.76492ff7.woff2) format("woff2");
    font-display: swap;
}

@font-face {
    src: url(/static/media/seriff-regular-webfont.4ceaf0a7.woff) format("woff");
    font-family: "seriff";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "seriff";
    src: url(/static/media/seriff-heavy-webfont.45cef2f2.woff) format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Font Awesome 6 Pro";
    src: url(/static/media/fa-light-300.4a9ab29a.woff2) format("woff2");
    font-weight: 100;
    font-display: swap;
}

body {
  margin: 0;
  font-family: "Montserrat", "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}
/*
div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
} */

.web3modal-provider-name {
    background-color: transparent;
}

.web3modal-provider-description {
    background-color: transparent;
}

.link {
    display: inline-block;
    transition: 0.2s;
    color: white;
    text-decoration: none;
}

.mr {
    margin-right: 20px;
}

.ml {
    margin-left: 20px;
}

.link:hover {
    opacity: 0.67;
    /* text-decoration: underline; */
}

.fa-thin, .fat {
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
}

.fa-user:before {
    content: "\f007";
}

.fa-bars:before {
    content: "\f0c9";
}

.fa-xmark:before {
    content: "\f00d";
}

.mailchimp {
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    line-height: 140%;
    border-width: 2px;
    border-color: white;
    border-style: solid;
}

.mailchimp input {
    max-width: 300px;
    width: 52.5vw;
    height: 1.6em;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.9);
    border-style: none;
    outline: none;
    padding: 0.5em;
    font-family: regular, sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.mailchimp button {
    text-align: center;
    cursor: pointer;
    font-family: regular, sans-serif;
    background-color: white;
    color: black;
    border-style: none;
    padding: 0.5em 1.6em;
    font-size: 20px;
}

@media (max-width: 480px) {
.mailchimp button {
    padding: 0.5em 1em;
}}

.mailchimp ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: regular, sans-serif;
    font-weight: normal;
}

.mailchimp :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: regular, sans-serif;
    font-weight: normal;
}

.mailchimp ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    font-family: regular, sans-serif;
    font-weight: normal;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 2.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.loader-hidden {
    opacity: 0;
    transition: 400ms;
    display: none !important;
}

.menu {
    transition: 1000ms;
}

.menu-hidden {
    opacity: 0;
    display: none !important;
}

.menu-shown {
    opacity: 1;
}

.vp-unmute {
    display: none !important;
}

